import r2wc from "@r2wc/react-to-web-component"

import ImageFullscreenBanner from "./ImageFullscreenBanner"
import HTMLStickyBanner from "./HTMLStickyBanner"
import HTMLFullscreenBanner from "./HTMLFullscreenBanner"
import ImageBanner from "./ImageBanner";
import HTMLBanner from "./HTMLBanner";


const HTMLFullscreenBannerWC = r2wc(HTMLFullscreenBanner, {
  props: {
    url: "string",
    device: "string",
    width: "string",
    height: "string",
    mobileWidth: "string"
  }
})

const ImageFullscreenBannerWC = r2wc(ImageFullscreenBanner, {
  props: {
    url: "string",
    device: "string",
    width: "string",
    height: "string",
    mobileWidth: "string"
  }
})

const HTMLStickyBannerWC = r2wc(HTMLStickyBanner, {
  props: {
    url: "string",
    device: "string",
    width: "string",
    height: "string",
  }
})

const ImageBannerWC = r2wc(ImageBanner, {
  props: {
    url: "string",
    device: "string",
    width: "string",
  }
})

const HTMLBannerWC = r2wc(HTMLBanner, {
  props: {
    url: "string",
    device: "string",
    width: "string",
    height: "string",
  }
})

customElements.define("html-fullscreen-banner", HTMLFullscreenBannerWC)
customElements.define("image-fullscreen-banner", ImageFullscreenBannerWC)
customElements.define("html-sticky-banner", HTMLStickyBannerWC)
customElements.define("image-banner", ImageBannerWC)
customElements.define("html-banner", HTMLBannerWC)
