import React, {FC, PropsWithChildren, ReactNode, RefObject} from "react"
import styled from 'styled-components'
import {useMedia} from "react-use";
import {DeviceType} from "../../Utils/deviceType"

const BannerContainerWrapper = styled.div`
`

type Props = {
  className?: string
  header?: ReactNode
  device: DeviceType
  innerRef?: RefObject<HTMLDivElement>
}

const NotAvailable = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Arial, Helvetica, sans-serif;
    color: color-mix(in srgb, #6b6b6b 20%, #2c2c2c 100%);
    height: 100%;
    text-align: center;
    background: #e7e7e7;
    padding: 20px;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
`

const ContentWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
`

const BannerContainer: FC<PropsWithChildren<Props>> = (
  {
    children,
    header,
    className,
    device,
    innerRef,
  }) => {
  const isWide = useMedia('(min-width: 568px)')
  const content = device === DeviceType.all
  || (isWide && device === DeviceType.desktop)
  || (!isWide && device === DeviceType.mobile)
    ? children
    : <>
      {children}
      <NotAvailable><p>Формат не поддерживается на данном устройстве</p></NotAvailable>
    </>

  return <BannerContainerWrapper ref={innerRef} className={className}>
    <>
      {header}
      <ContentWrapper>
        {content}
      </ContentWrapper>
    </>
  </BannerContainerWrapper>
}
export default BannerContainer