import React, {FC} from "react"
import {DeviceType} from "../Utils/deviceType";
import BannerContainer from "../Components/BannerContainer";
import styled from "styled-components";

type Props = {
  device: DeviceType
  url: string
  width: string
  height: string
}

const Frame = styled.iframe`
    border: none;
    width: 100%;
`
const HTMLStickyBannerWrapper = styled(BannerContainer)`
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
`
type HTMLStickyBannerInnerProps = {
  $width: string
  $height: string
}

const HTMLStickyBannerInner = styled.div<HTMLStickyBannerInnerProps>`
    height: ${(props) => props.$height};
    width: ${(props) => props.$width};
`

const HTMLStickyBanner: FC<Props> = (
  {
    device,
    url,
    width = "70%",
    height = "20vh"
  }) => {
  return <HTMLStickyBannerWrapper device={device}>
    <HTMLStickyBannerInner $width={width} $height={height}>
      <Frame src={url}/>
    </HTMLStickyBannerInner>
  </HTMLStickyBannerWrapper>
}

export default HTMLStickyBanner