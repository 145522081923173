import React, {FC, useEffect, useMemo, useRef} from "react"
import styled, {css} from 'styled-components'
import BannerContainer from "../Components/BannerContainer"
import BannerHeader from "../Components/BannerHeader";
import {DeviceType} from "../Utils/deviceType";
import {useMedia} from "react-use";

const FullscreenBannerContainerBackground = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
`


type Props = {
  device: DeviceType
  url: string
  width: string
  mobileWidth: string
  height: string
  image: boolean
}

type FullscreenBannerContainerProps = {
  width: string
  mobileWidth: string
  height: string
  isDesktop: boolean
  isImage: boolean
  showNotAllowed: boolean
}

const FullscreenBannerHeader = styled(BannerHeader)`
    P {
        color: color-mix(in srgb, #000000 20%, #ffffff 100%);
    }
`

const FullscreenBannerContainer = styled(BannerContainer)<FullscreenBannerContainerProps>`
    overflow: hidden;
    width: ${(props) => props.width};
    ${(props) => !props.isImage && css`
        height: ${props.height};
    `};

    @media (max-width: 568px) {
        width: ${(props) => props.mobileWidth};
    }
    
    ${(props) => props.showNotAllowed && css`
        height: 80%;
        width: 80%;
    `}

    & img {
        width: 100%;
    }

    & iframe {
        width: 100%;
        height: 100%;
        border: none;
    }

`

const FullscreenBanner: FC<Props> = (
  {
    device = DeviceType.all,
    url,
    width = "70%",
    mobileWidth,
    height = "50vh",
    image = false
  }
) => {
  const content = image ? <img src={url} alt="img"/> : <iframe src={url}></iframe>

  const banner = useRef<HTMLDivElement>(null)

  const isWide = useMedia('(min-width: 568px)')
  const showNotAllowed = useMemo(() => !(device === DeviceType.all
      || (isWide && device === DeviceType.desktop)
      || (!isWide && device === DeviceType.mobile)), [isWide, device]);
  const onClose = () => {
    banner.current!.remove()
  }
  const isDesktop = useMedia('(min-width: 568px)')


  return <FullscreenBannerContainerBackground ref={banner}>
    <FullscreenBannerContainer
      header={
        <FullscreenBannerHeader onClose={onClose}/>
      }
      width={width}
      mobileWidth={mobileWidth}
      height={height}
      device={device}
      isDesktop={isDesktop}
      isImage={image}
      showNotAllowed={showNotAllowed}
    >
      {content}
    </FullscreenBannerContainer>
  </FullscreenBannerContainerBackground>
}
export default FullscreenBanner