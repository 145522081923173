import React, {FC, useRef} from "react"
import {DeviceType} from "../Utils/deviceType";
import BannerContainer from "../Components/BannerContainer";
import BannerHeader from "../Components/BannerHeader";
import styled from "styled-components";

type Props = {
  device: DeviceType
  url: string
  width: string
}

const Banner = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`

const Image = styled.img<{
  $width: string
}>`
    width: ${(props) => props.$width};
`

const HTMLBanner: FC<Props> = (
  {
    device = DeviceType.all,
    url,
    width = "100%",
  }) => {
  const banner = useRef<HTMLDivElement>(null)
  const onClose = () => {
    banner.current!.remove()
  }

  return <BannerContainer
    innerRef={banner}
    device={device}
    header={
      <BannerHeader onClose={onClose}/>
    }>
    <Banner>
      <Image src={url} $width={width}/>
    </Banner>
  </BannerContainer>
}

export default HTMLBanner