import {FC} from "react"
import FullscreenBanner from "../FullscreenBanner"
import {DeviceType} from "../Utils/deviceType"

type Props = {
  device: DeviceType
  url: string
  width: string
  mobileWidth: string
  height: string
}
const HTMLFullscreenBanner: FC<Props> = (props) => {
  return <FullscreenBanner image={false} {...props}/>
}

export default HTMLFullscreenBanner