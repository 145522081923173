import React, {FC, useRef} from "react"
import {DeviceType} from "../Utils/deviceType";
import BannerContainer from "../Components/BannerContainer";
import BannerHeader from "../Components/BannerHeader";
import styled from "styled-components";

type Props = {
  device: DeviceType
  url: string
  width: string
  height: string
}

const Banner = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`

const Frame = styled.iframe<{
  $width: string,
  $height: string,
}>`
    border: none;
    width: ${(props) => props.$width};

    aspect-ratio: 16/9;
    height: ${(props) => props.$height};
    overflow: hidden;
`

const ImageBanner: FC<Props> = (
  {
    device = DeviceType.all,
    url,
    width,
    height,
  }) => {
  const banner = useRef<HTMLDivElement>(null)
  const onClose = () => {
    banner.current!.remove()
  }

  return <BannerContainer
    innerRef={banner}
    device={device}
    header={
      <BannerHeader onClose={onClose}/>
    }>
    <Banner>
      <Frame src={url} $width={width} $height={height}/>
    </Banner>
  </BannerContainer>
}

export default ImageBanner